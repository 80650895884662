import React,{useState,} from 'react'
import { Table, TableBody, IconButton,
    TableContainer, TableHead, TableRow,    
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import {celda} from '../../Generales/TablaGeneral';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import MediaTema from './MediaTema';
//import { printConsole } from '../../funciones/ConsolaLog';
const useStyles = makeStyles()((theme ) => {
    return {
        root: {
            width: '100%',
        },
        container: {
            height: window.innerHeight-500,
        },
        tableRow: {
            "&$selected, &$selected:hover": {
                backgroundColor: "#F46239"
            }
        },
        tableCell: {
            "$selected &": {
                color: "white"
            }
        },
        hover: {},
        selected: {}
    };
  });

const TablaTema = ({datos}) => {
    const classes = useStyles();
    const [selected, setSelected] = useState(null);  
    const [modalMedia, setModalMedia] = useState(false); 
    const abrirModalMedia=(e)=> {
        e.preventDefault()
        setModalMedia(true)
    }
    function Row(props) {
        const {row} = props;        
        return (           
        <TableRow
            onClick={() => { setSelected(row) }}
            selected={selected?.Id === row.Id} className={classes.tableRow}
            classes={{hover: classes.hover, selected: classes.selected}}
        >    
            {celda("center", {}, 
               row.Links.length!==0?
                <IconButton size="small" onClick={abrirModalMedia}>
                    <VideoLibraryIcon/>
                </IconButton>:null
            ,1, classes.tableCell)}                     
            {celda("center", {}, row.Num, 7, classes.tableCell)}      
            {celda("left", {width: 250 + 'px'}, row.Titulo, 2, classes.tableCell)}      
            {celda("center", {width: 40 + 'px'}, row.Tiempo, 3, classes.tableCell)}            
            {celda("left", {}, row.AlbumNom, 4, classes.tableCell)}
            {celda("left", { }, row.Autor, 5, classes.tableCell)}
            {celda("center", {}, row.Editora, 6, classes.tableCell)}
            
        </TableRow>                          
        );
    }

    const tablaCompleta = () => {

        return (
        <TableContainer className={classes.container}  sx={{  height:  window.innerHeight- 200,}} >
        <Table stickyHeader aria-label="sticky table"  id="tablaTema" sx={{  height:  window.innerHeight- 200,}} >
            <TableHead>
                <TableRow>
                    {celda("left", {}, "", 1, "")}
                    {celda("center", {}, "No",7, "")}       
                    {celda("center", {}, "Titulo",5, "")}                                                
                    {celda("center", {}, "Duración",6, "")}   
                    {celda("center", {}, "Album", 2, "")}
                    {celda("center", {}, "Autor", 3, "")}
                    {celda("center", {}, "Editora",4, "")}
                                                                     
                </TableRow>
            </TableHead>
            <TableBody>                                                                 
                {datos.temas.length !== 0 && datos.temas[0].Id !== - 1? 
                    (datos.temas.map((row) => (
                        <Row key={row.Id} row={row}/>
                    ))) 
                : <TableRow className={classes.tableRow} >            
                {celda("center", {}, "", 6, classes.tableCell)}
                {celda("center", {}, "", 1, classes.tableCell)}
                {celda("center", {}, "Sin", 2, classes.tableCell)}
                {celda("center", {}, "", 3, classes.tableCell)}
                {celda("center", {}, "Registros", 4, classes.tableCell)}
                {celda("center", {}, "", 5, classes.tableCell)}
                {celda("center", {}, "", 7, classes.tableCell)}
            </TableRow>      }
            </TableBody>
        </Table>
        </TableContainer>
        )
    }
    const modMedia = modalMedia? <MediaTema modalAbierto={modalMedia} setModalAbierto={setModalMedia} registro={selected}/>:null
  return (
    <>
        { tablaCompleta()  }
        {modMedia}
    </>
  )
}

export default TablaTema