import React,{useState,useEffect} from 'react'
import { IconButton, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,    
} from '@mui/material';
import { makeStyles } from 'tss-react/mui'
import moment from 'moment';
import {celda} from '../../Generales/TablaGeneral';
import { printConsole } from '../../funciones/ConsolaLog';
//import NuevoContrato from '../Contrato/NuevoContrato';
import {AddCircleOutline} from '@mui/icons-material';
const useStyles = makeStyles()((theme) => {
    return {
        root: {
            width: '100%',
        },
        container: {
            maxHeight: 420,
        },
        tableRow: {
            "&$selected, &$selected:hover": {
                backgroundColor: "#F46239"
            }
        },
        tableCell: {
            "$selected &": {
                color: "white"
            }
        },
        hover: {},
        selected: {}
    };
  });

const TablaContrato = ({datos}) => {
    const classes = useStyles();
    const [selectedID, setSelectedID] = useState(null);
    const [modalNvoContrato,setModalNvoContrato]= useState(false);
    useEffect(() => {
        printConsole(datos)
    
    
    }, [ ])
 
    function Row(props) {
        const {row} = props;        
        return (           
        <TableRow
            onClick={() => { setSelectedID(row) }}
            selected={selectedID?.Id === row.Id} className={classes.tableRow}
            classes={{hover: classes.hover, selected: classes.selected}}
        >
            <TableCell align="center" padding={'none'} style={{width: 30 + 'px'}}>                 
            </TableCell> 
           
            {/*  celda("center", { },  "", 2, classes.tableCell)  */}            
            {celda("center", {width: 45+'px'}, 
                row.Fini=== "1900-01-01 00:00" ? 
                "" : moment.utc( row.Fini).format('DD/MM/YYYY')
            , 4, classes.tableCell)}   
            {celda("center", {width: 45+'px'}, 
                row.Ffin=== "1900-01-01 00:00" ? 
                "" : moment.utc( row.Ffin).format('DD/MM/YYYY')
            , 5, classes.tableCell)}      
            {celda("center", {width: 45+'px'}, 
                row.Ffirma=== "1900-01-01 00:00" ? 
                "" : moment.utc( row.Ffirma).format('DD/MM/YYYY')
            , 6, classes.tableCell)}                          
            {celda("left", {}, row.Descr, 1, classes.tableCell)}
            {celda("left", {}, row.Observ, 3, classes.tableCell)}
        </TableRow>                          
        );
    }

const tablaCompleta = () => {

    return (
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow>
                {celda("left", {}, "", 1, "")}                
                {/* celda("center", {}, "Representante", 3, "") */}
                {celda("center", {}, "F.Inicio",2, "")}   
                {celda("center", {}, "F.Fin",3, "")}     
                {celda("center", {}, "F.Firma",4, "")}     
                {celda("center", {}, "Descripción", 5, "")}
                {celda("center", {}, "Observaciones",6, "")}
                                                                                            
            </TableRow>
        </TableHead>
        <TableBody>                                                                 
            {datos.contrato_usr.length !== 0 && datos.contrato_usr[0].Id !== - 1 ? 
                (datos.contrato_usr.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) 
            : 
            <TableRow className={classes.tableRow} >            
                {celda("center", {}, "", 1, classes.tableCell)}
                {celda("center", {}, "", 2, classes.tableCell)}
                {celda("center", {}, "Sin", 3, classes.tableCell)}
                {celda("center", {}, "", 4, classes.tableCell)}
                {celda("center", {}, "Registros", 5, classes.tableCell)}
                {celda("center", {}, "", 6, classes.tableCell)}
            </TableRow>       
            }
        </TableBody>
    </Table>
    </TableContainer>
    )
    }
/* 
    const modNvoContrato= modalNvoContrato ? <NuevoContrato modalAbierto={modalNvoContrato} 
                                                setModalAbierto={setModalNvoContrato} registro={selectedID} /> :null */
  return (
    <>
        {/* modNvoContrato */}
        {tablaCompleta()}
    </>
  )
}

export default TablaContrato