import {Box, Button, CircularProgress, Grid, InputAdornment, TextField} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../Generales/AuthContext";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import {warningToast} from "../../Generales/Tools";
import {GeneralContext} from "../../Generales/GeneralContext";
/* import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {AccountCircle} from "@mui/icons-material"; */
//import moment from 'moment';

const Login = () => {

    const navigate = useNavigate()
    const logo = 'DADUVA_cuadrado.svg'
    const {guardarUsuario} = useContext(AuthContext);
    const [datos, setDatos] = useState({user: '', password: '', telefono:"", email:"", nombre:""})
    const [inputType, setInputType] = useState('password');
    const [userError, setUserError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [espera, setEspera] = useState(false)
    const {telefono, email, nombre}=datos
    const {genelares,setGenerales} = useContext(GeneralContext);
    
    const onDownload = (response) => {  
        
        let a = document.createElement('a');
        a.href = response;
        a.download = "registro"+".apk";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);   
        
        //URL.revokeObjectURL(response);           
    };

    function authUser(user) {
        let auxCode = uuidv4()
        const disp=auxCode.slice(auxCode.lastIndexOf("-") + 1, auxCode.length)
        axios.post(process.env.REACT_APP_LINK + 'registro/v1/new', 
            { nombre: nombre, telefono: telefono, email:email  }).then(function (response) {
                const respuesta=response.data
            if (respuesta.respuesta===1) {
                //console.log("subiendo",respuesta,respuesta.link);
                
                setEspera(false)
                 let nvoLink=`${respuesta.link}`
                // console.log(nvoLink);
                 localStorage.setItem('info',nvoLink);
                //guardarUsuario({Nombre:"rdrigu", nivel: 1,link:nvoLink});
                //setGenerales({link:nvoLink,})
                setDatos({user: '', password: '', telefono:"", email:"", nombre:""})            
                onDownload(nvoLink)
                /* setTimeout(() => {
                    navigate('/descarga')
                }, 200); */
            } else {
                setEspera(false)
                warningToast('Nombre o Télefono incorrectos')
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onChangeNom = e =>
	{   let expNopermitida = new RegExp('[0-9$.@#_&-+()/:;!?,<>=%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setDatos({...datos, [e.target.name] : e.target.value})            
        }		
	};   

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setDatos({...datos, [e.target.name] : e.target.value})                        
        }		    

	}; 
     
    const handleUser = (event) => {
        setUserError('')
        setPasswordError('')
        setDatos({...datos, [event.target.name]: event.target.value});
    }

    const handleLogin = (event) => {
        setEspera(true)
        event.preventDefault();
        if (nombre === '' || telefono === '') {
            if (telefono === '') {
                setPasswordError('Debe ingresar un Télefono')
                setEspera(false)
            }
            if (nombre === '') {
                setUserError('Debe ingresar un Nombre')
                setEspera(false)
            }
        } else {
            authUser(datos)
        }
    }

    return (
    <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'}
        style={{width: '100vw', height: '100vh'}}>
        <Grid item xs={8} md={3}>
            <form onSubmit={handleLogin} noValidate autoComplete={'off'}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img src={logo} width={'80%'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="nom-text" name="nombre" label="Nombre" variant="outlined"
                            error={userError !== '' ? true : false} helperText={userError} fullWidth
                            onChange={onChangeNom} value={nombre}                                
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="tel-text" name="telefono" label="Télefono" variant="outlined"
                            error={passwordError !== '' ? true : false} value={telefono}
                            helperText={passwordError} fullWidth onChange={onChangeNumTel}  
                            inputProps= {{ maxlength: 10 }}       type="number"                          
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="email-text" name="email" label="Email" variant="outlined"
                            //error={passwordError !== '' ? true : false} helperText={passwordError}
                            value={email} fullWidth onChange={handleUser}                                 
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {espera ? <Box> <CircularProgress/> </Box> :
                            <Button variant={'contained'} color={'primary'} fullWidth
                                type={'submit'}>Ingresar</Button>}
                    </Grid>
                </Grid>
            </form>
        </Grid>
    </Grid>);
};

export default Login;
