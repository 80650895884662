import React, { useEffect, useState,useContext } from "react";
import {Box, Card, CardContent, IconButton, LinearProgress,
  TextField,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Search as SearchIcon } from "@mui/icons-material/";
import axios from "axios";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import MenuAndroid from '../AppAndroid';
import { ErrorGeneral, llamaApiCargaToken } from "../../funciones/ClienteAxios";
import {GeneralContext} from "../../Generales/GeneralContext";
import {AuthContext} from "../../Generales/AuthContext";
//import moment from 'moment';
const useStyles = makeStyles()((theme) => {
  return {
    root: {
      minWidth: 275,
    },
    title: {
      fontSize: 28,
    },
    title2: {
      fontSize: 18,
    },
    subTitle2: {
      fontSize: 12,
    },
    pos: {
      marginBottom: 12,
    },
    addButton: {
      marginRight: theme.spacing(1),
    },
    altura2: {
     // height: 70,
     height:"90vh",
      //width:'100%',
      marginTop: 0,
      paddingTop: 0,
      // width:275
    },
    altura3: {
      height: 145,
      marginTop: 0,
      paddingTop: 0,
      // width:275
    },
    altura1: {
      height: 90,
    },
  };
});

const Busqueda = () => {
  const { classes } = useStyles();
  const source = axios.CancelToken.source();
  const history = useNavigate();
  const [filtro, setFiltro] = useState({ registro: "" });
  const [espera, setEspera] = useState(false);
  const [datos, setDatos] = useState([]);
  const {usuario,guardarUsuario} = useContext(AuthContext);
  const {genelares,} = useContext(GeneralContext);
  const [lnik2, setlnik2] = useState("");
  const { registro } = filtro;

  useEffect(() => {
    const nvl = localStorage.getItem('info');

    if (usuario.length===0) 
    {
        guardarUsuario({...usuario,link:nvl})    
    }
    console.log(genelares,nvl,usuario);
    setlnik2(nvl)
    return () => {
      source.cancel();
    };
  }, []);
 
  return (
    <Box style={{ padding: 0, margin: 0, height:"90vh"}} alignItems={'center'} justifyContent={'center'} textAlign={'center'}>
      {/* <Card className={classes.altura2}>
        <CardContent>
        
        </CardContent>
      </Card> */}
      <Box display="flex" flexDirection="row" alignItems={'center'} justifyContent={'center'} textAlign={'center'}
            style={{height:"90vh"}}
         >
            {/*  <Box pr={1}>
              <IconButton
                color="primary"
                aria-label="add circle"
                size="small"
                component="span"
                style={{ marginTop: 0.7 + "rem" }}
                onClick={Buscar}
              >
                <SearchIcon />
              </IconButton>
            </Box> */}
            <Box>
                <MenuAndroid link={lnik2} usuario={usuario} />  
            </Box>
          </Box>
    </Box>
  );
};

export default Busqueda;
